import logo from './logo.svg';
import './App.css';
import landsraadlogo from './static/images/LR logo.svg'
import landsraadimg from './static/images/landsraadMid.png'

function App() {
  return (
    <div className="App">
      <div className='main-container'>
        <div className='header-container'>
          <img src={landsraadlogo} className='lr-logo'/>
        </div>
        <div className='hero-container'>
          <div className='hero-image'>
            <img src={landsraadimg} className='landsraad-img' />
          </div>
          <div className='hero-text'>
            <h1>Rethinking the science of prediction</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
